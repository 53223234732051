import WhiteMidCTA from "../components/cta/WhiteMidCTA";
import { FAQOne } from "../components/faq/FAQOne";
import { FAQTwo } from "../components/faq/FAQTwo";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";

const FAQ = () => {
  return (
    <>
      <div style={{ backgroundColor: "#05080b" }}>
        <Header />
        <FAQTwo />
      </div>
      <WhiteMidCTA />
      <FooterOne />
    </>
  );
};

export default FAQ;
