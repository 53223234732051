export default function TitleFive() {
  return (
    <div className="bg-[#05080b] py-24 sm:py-32">
      <div className="mx-auto max-w-[1545px] px-6 lg:px-8">
        <div className="mx-auto max-w-5xl lg:mx-0">
          <h2 className="text-[45px] font-[600] max-w-[800px] tracking-tight text-white sm:text-6xl">
            Trade shares on our platform
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            A CFD, or Contract for Difference, is a type of financial instrument
            that allows you to trade on the price movements of stocks,
            regardless of whether prices are rising or falling. The key
            advantage of a CFD is the opportunity to speculate on the price
            movements of an asset (upwards or downwards) without actually owning
            the underlying asset.
            <br />
            <br />
            Stock trading has been a popular financial pursuit since stocks were
            first introduced by the Dutch East India Company in the 17th
            century. This is both an efficient and effective type of investment
            for both families and individuals.
          </p>

          <p className="mt-6 text-lg leading-8 text-gray-300">
            Our platform is a comprehensive gateway to the stock markets,
            meticulously designed to equip you with all the tools needed for
            your trading success.
            <br />
            <br />
            We provide access to real-time market data, advanced analytics,
            customizable charts, and a suite of intuitive trading instruments,
            all seamlessly integrated to enhance your decision-making process.
            Whether you're a novice embarking on your trading journey or a
            seasoned professional seeking to refine your strategies, our
            platform offers tailored solutions to meet your unique needs.
            Benefit from expert guidance, ongoing education, and responsive
            customer support that puts you at the center of our mission.
            <br />
            <br />
            Join us and discover a trading environment that prioritizes your
            growth, security, and success, connecting you to global
            opportunities with confidence and ease.
          </p>

          {/* <div className="text-white">
            <h2 className="mt-16 text-2xl font-[600] tracking-tight text-white">
              What is margin?
            </h2>
            <p className="mt-6">
              Margin is the amount of a trader’s funds required to open a new
              position. Margin is estimated based on the size of your trade,
              which is measured in lots. A standard lot is 100,000 units. We
              also provide mini lots (10,000 units), micro lots (1,000 units)
              and nano lots (100 units). The greater the lot, the bigger the
              margin amount. Margin allows you to trade with leverage, which, in
              turn, allows you to place trades larger than the amount of your
              trading capital. Leverage influences the margin amount too.
            </p>
          </div> */}

          {/* <div className="text-white">
            <h2 className="mt-16 text-2xl font-[600] tracking-tight text-white">
              What is leverage?
            </h2>
            <p className="mt-6">
              Leverage is the ability to trade positions larger than the amount
              of capital you possess. This mechanism allows traders to use extra
              funds from a broker in order to increase the size of their trades.
              For example, 1:100 leverage means that a trader who has deposited
              $1,000 into his or her account can trade with $100,000. Although
              leverage lets traders increase their trade size and, consequently,
              potential gains, it magnifies their potential losses putting their
              capital at risk.
            </p>
          </div> */}

          {/* <div className="text-white">
            <h2 className="mt-16 text-2xl font-[600] tracking-tight text-white">
              What is the forex market open?
            </h2>
            <p className="mt-6">
              Due to different time zones, the international forex market is
              open 24 hours a day — from 5 p.m. Eastern Standard Time (EST) on
              Sunday to 4 p.m. EST on Friday, except holidays. Markets first
              open in Australasia, then in Europe and afterwards in North
              America. So, when the market closes in Australia, traders can have
              access to markets in other regions. The 24-hour availability of
              the forex market is what makes it so attractive to millions of
              traders.
            </p>
          </div> */}

          {/* <p className="mt-6 text-lg leading-8 text-gray-300">
            Move from an idea to getting your business started with the tools,
            resources, community and partnerships you need to succeed. Move from
            an idea to getting your business started with the tools, resources,
            community and partnerships you need to succeed.
          </p> */}
        </div>
      </div>
    </div>
  );
}
