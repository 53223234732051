import React from "react";
import ContextText from "../components/content/ContentText";
import { Header } from "../components/header/Header";
import FooterOne from "../components/footer/FooterOne";
import PrivacyText from "../components/content/PrivacyText";
import TermsText from "../components/content/TermsText";

const Terms = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#05080b" }}>
        <Header />
      </div>
      <TermsText />
      <FooterOne />
    </div>
  );
};

export default Terms;
