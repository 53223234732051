import ContentOne from "../components/content/ContentOne";
import CTAThree from "../components/cta/CTAThree";
import CTATwo from "../components/cta/CTATwo";
import DarkCTA from "../components/cta/DarkCTA";
import FAQThree from "../components/faq/FAQThree";
import FeaturesEight from "../components/features/FeaturesEight";
import FeaturesFive from "../components/features/FeaturesFive";
import FeaturesSeven from "../components/features/FeaturesSeven";
import FeaturesSix from "../components/features/FeaturesSix";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import HeroFive from "../components/hero/HeroFIve";
import HeroThree from "../components/hero/HeroThree";
import HeroTwo from "../components/hero/HeroTwo";
import TitleFive from "../components/section-title/TitleFive";
import TitleFour from "../components/section-title/TitleFour";
import TitleSix from "../components/section-title/TitleSix";
import TitleThree from "../components/section-title/TitleThree";
import TitleTwo from "../components/section-title/TitleTwo";

const faqs = [
  {
    question: "What Is Margin?",
    answer:
      "Margin is the amount of a trader’s funds required to open a new position. Margin is estimated based on the size of your trade, which is measured in lots. A standard lot is 100,000 units. We also provide mini lots (10,000 units), micro lots (1,000 units) and nano lots (100 units). The greater the lot, the bigger the margin amount. Margin allows you to trade with leverage, which, in turn, allows you to place trades larger than the amount of your trading capital. Leverage influences the margin amount too.",
  },
  {
    question: "What Is Leverage?",
    answer:
      "Leverage is the ability to trade positions larger than the amount of capital you possess. This mechanism allows traders to use extra funds from a broker in order to increase the size of their trades. For example, 1:100 leverage means that a trader who has deposited $1,000 into his or her account can trade with $100,000. Although leverage lets traders increase their trade size and, consequently, potential gains, it magnifies their potential losses putting their capital at risk.",
  },

  {
    question: "When Is The Forex Market Open?",
    answer:
      "Due to different time zones, the international forex market is open 24 hours a day — from 5 p.m. Eastern Standard Time (EST) on Sunday to 4 p.m. EST on Friday, except holidays. Markets first open in Australasia, then in Europe and afterwards in North America. So, when the market closes in Australia, traders can have access to markets in other regions. The 24-hour availability of the forex market is what makes it so attractive to millions of traders.",
  },
];

const Forex = () => {
  return (
    <>
      <div style={{ backgroundColor: "#05080b" }}>
        <Header />
      </div>
      <TitleSix />
      <FeaturesSeven />
      <FAQThree faqs={faqs} />
      <DarkCTA />
      <FooterOne />
      {/* <TitleThree /> */}
      {/* <TitleFive /> */}
      {/* <TitleFour /> */}
      {/* <TitleTwo /> */}
      {/* <HeroThree /> */}
      {/* <FeaturesEight /> */}
      {/* <div className="py-24"><ContentOne /></div> */}
      {/* <div style={{ backgroundColor: "#05080b" }}> */}
      {/* <CTATwo /> */}
      {/* <CTAThree /> */}
      {/* <FeaturesSix /> */}
      {/* <br /> */}
      {/* <br /> */}
      {/* </div> */}
    </>
  );
};

export default Forex;
