import React from "react";
import ContextText from "../components/content/ContentText";
import { Header } from "../components/header/Header";
import FooterOne from "../components/footer/FooterOne";
import PrivacyText from "../components/content/PrivacyText";

const Privacy = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#05080b" }}>
        {/* <FeaturesTwoWithImage /> */}
        <Header />
      </div>
      <PrivacyText />
      <FooterOne />
    </div>
  );
};

export default Privacy;
