import AboutContent from "../components/content/AboutContent";
import AboutThree from "../components/content/AboutThree";
import AboutTwo from "../components/content/AboutTwo";
import CTAFour from "../components/cta/CTAFour";
import CTAThree from "../components/cta/CTAThree";
import CTATwo from "../components/cta/CTATwo";
import { CTAOne } from "../components/cta/CtaOne";
import FeaturesFive from "../components/features/FeaturesFive";
import { FeaturesFour } from "../components/features/FeaturesFour";
import { FeaturesOne } from "../components/features/FeaturesOne";
import FeaturesThree from "../components/features/FeaturesThree";
import { FeaturesTwoWithImage } from "../components/features/FeaturesTwoWithImage";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import { Hero } from "../components/hero/Hero";
import StatsOne from "../components/stats/StatsOne";
import StatsTwo from "../components/stats/StatsTwo";
import { TestimonialOne } from "../components/testimonials/TestimonialOne";

const About = () => {
  return (
    <>
      <div style={{ backgroundColor: "#05080b" }}>
        <Header />
      </div>
      <AboutTwo />
      <FeaturesFive />
      <FooterOne />
    </>
  );
};

export default About;
