export default function TitleSix() {
  return (
    <div className="bg-[#05080b] overflow-hidden relative">
      <div className=" isolate pt-14 ">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-sm sm:-top-80"
          aria-hidden="true"
        >
          <div class="Hero-module--Hero_backgroundImage--ObUJ0">
            <img
              src="https://i.gifer.com/Tfwd.gif"
              alt="hero"
              style={{
                filter: "brightness(.1)",
                width: "100%",
                opacity: "0.05",
                userSelect: "none",
                pointerEvents: "none",
                position: "'absolute",
              }}
            />
          </div>
        </div>
        <div className="py-24 sm:py-32 lg:pb-40   ">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
            <div
              className="mx-auto text-center "
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <h1 className="text-4xl font-bold tracking-tight max-w-4xl text-white sm:text-6xl">
                Trade foreign exchange on our platform
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300 max-w-4xl">
                Forex is short for foreign exchange. The forex market is a place
                where currencies are traded. It is the largest and most liquid
                financial market in the world with an average daily turnover of
                6.6 trillion U.S. dollars as of 2019. The basis of the forex
                market is the fluctuations of exchange rates. Forex traders
                speculate on the price fluctuations of currency pairs, making
                money on the difference between buying and selling prices.
              </p>
              {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#"
                  className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Get started
                </a>
                <a
                  href="#"
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        ></div>
      </div>
    </div>
  );
}
